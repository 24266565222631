<dialog
  #modal
  role="dialog"
  class="govuk-timeout-warning dialog fixed"
  aria-live="polite"
  aria-labelledby="dialog-title"
  aria-describedby="at-timer"
>
  <app-page-heading size="l" id="dialog-title">Your session will end soon</app-page-heading>
  <div class="govuk-body">
    <ng-container *ngIf="timeoutBannerService.timeExtensionAllowed$ | async; else noMoreTimeExtension">
      <div aria-hidden="true" aria-relevant="additions">
        You must select "Continue" within the next
        {{ timeoutBannerService.timeOffsetSeconds | secondsToMinutes }} to stay signed in to the UK ETS reporting
        service. You will be automatically signed out if you do not respond, and your progress will not be saved.
      </div>
      <div class="govuk-visually-hidden" role="status">
        We will reset your application if you do not respond in
        {{ timeoutBannerService.timeOffsetSeconds | secondsToMinutes }}. We do this to keep your information secure.
      </div>
    </ng-container>
    <ng-template #noMoreTimeExtension>
      <div aria-hidden="true" aria-relevant="additions">
        You will be automatically signed out of the the UK ETS reporting service within the next
        {{ timeoutBannerService.timeOffsetSeconds | secondsToMinutes }}. Your progress will not be saved. Select
        "Continue" to complete and save any unfinished work before you are automatically signed out.
      </div>
      <div class="govuk-visually-hidden" role="status">
        We will reset your application in
        {{ timeoutBannerService.timeOffsetSeconds | secondsToMinutes }}. We do this to keep your information secure.
      </div>
    </ng-template>
  </div>
  <button govukButton type="button" (click)="continue()">Continue</button>
  <button type="button" govukSecondaryButton (click)="signOut()">Sign out</button>
</dialog>
