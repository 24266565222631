<app-cookies-container *ngIf="(showCookiesBanner$ | async) === true"></app-cookies-container>
<govuk-header
  logoSvg="/assets/images/govuk-header-logotype-crown.svg"
  title="Manage your UK Emissions Trading Scheme reporting"
>
  <govuk-skip-link></govuk-skip-link>
  <govuk-header-actions-list>
    <select
      class="domain-picker"
      *ngIf="(isLoggedIn$ | async) && (canSwitchDomain$ | async)"
      [ngModel]="currentDomain$ | async"
      (ngModelChange)="onSwitchDomain($event)"
      aria-label="Choose a domain"
    >
      <option title="View related to installation" ngValue="INSTALLATION">Installation view</option>
      <option title="View related to aviation" ngValue="AVIATION">Aviation view</option>
    </select>
    <a *ngIf="isLoggedIn$ | async" class="govuk-header__link" routerLink="." (click)="authService.logout()">
      Sign out
    </a>
  </govuk-header-actions-list>
  <app-navigation *ngIf="permissions$ | async as permissions">
    <a
      *ngIf="permissions.showRegulators || permissions.showVerifiers || permissions.showAuthorizedOperators"
      [routerLink]="[(domainUrlPrefix$ | async) + '/dashboard']"
      appNavigationLink
    >
      Tasks
    </a>
    <a
      *ngIf="permissions.showRegulators || permissions.showVerifiers || permissions.showAuthorizedOperators"
      [routerLink]="[(domainUrlPrefix$ | async) + '/accounts']"
      appNavigationLink
    >
      Accounts
    </a>
    <a
      *ngIf="permissions.showRegulators"
      [routerLink]="[(domainUrlPrefix$ | async) + '/user/regulators']"
      appNavigationLink
    >
      Regulator users and contacts
    </a>
    <a
      *ngIf="permissions.showRegulators"
      [routerLink]="[(domainUrlPrefix$ | async) + '/verification-bodies']"
      appNavigationLink
    >
      Verification body accounts
    </a>
    <a *ngIf="permissions.showRegulators" [routerLink]="[(domainUrlPrefix$ | async) + '/templates']" appNavigationLink>
      Templates
    </a>
    <a *ngIf="permissions.showRegulators" [routerLink]="[(domainUrlPrefix$ | async) + '/mi-reports']" appNavigationLink>
      MI Reports
    </a>
    <a
      *ngIf="permissions.showVerifiers"
      [routerLink]="[(domainUrlPrefix$ | async) + '/user/verifiers']"
      appNavigationLink
    >
      Manage verifier users
    </a>
    <a
      *ngIf="permissions.showRegulators"
      [routerLink]="[(domainUrlPrefix$ | async) + '/workflows/batch-variations']"
      appNavigationLink
    >
      Batch variations
    </a>
  </app-navigation>
</govuk-header>

<main class="govuk-width-container" id="main-content" role="main">
  <app-phase-bar></app-phase-bar>
  <app-incorporate-header></app-incorporate-header>
  <app-breadcrumbs></app-breadcrumbs>
  <app-identity-bar></app-identity-bar>
  <app-back-link></app-back-link>
  <div class="govuk-main-wrapper">
    <router-outlet appSkipLinkFocus></router-outlet>
  </div>
</main>
<govuk-footer>
  <govuk-footer-meta-info>
    <a govukLink="meta" routerLink="/privacy-notice"> Privacy Νotice </a>
    <a govukLink="meta" routerLink="/contact-us"> Contact us </a>
    <a govukLink="meta" routerLink="/accessibility"> Accessibility Statement </a>
    <a govukLink="meta" routerLink="/legislation"> UK ETS legislation </a>
  </govuk-footer-meta-info>
</govuk-footer>
<app-timeout-banner></app-timeout-banner>
