<app-page-heading size="l">Contact the UK Emissions Trading Scheme reporting helpdesk</app-page-heading>

<p class="govuk-body">
  If you need help accessing or using the UK Emissions Trading Scheme reporting service, please contact your regulator.
</p>

<ul class="govuk-list govuk-list--bullet" style="list-style-type: none">
  <li>
    Environment Agency (EA):
    <a class="govuk-link" href="mailto:ethelp@environment-agency.gov.uk"> ethelp&#64;environment-agency.gov.uk </a>
  </li>
  <li>
    Scottish Environment Protection Agency (SEPA):
    <a class="govuk-link" href="mailto:emission.trading@sepa.org.uk"> emission.trading&#64;sepa.org.uk </a>
  </li>
  <li>
    Northern Ireland Environment Agency (NIEA):
    <a class="govuk-link" href="mailto:emissions.trading@daera-ni.gov.uk"> emissions.trading&#64;daera-ni.gov.uk </a>
  </li>
  <li>
    Natural Resources Wales (NRW):
    <a class="govuk-link" href="mailto:GHGHelp@naturalresourceswales.gov.uk"> GHGHelp&#64;naturalresourceswales.gov.uk </a>
  </li>
  <li>
    Offshore Petroleum Regulator for Environment & Decommissioning (OPRED):
    <a class="govuk-link" href="mailto:opred@energysecurity.gov.uk"> opred&#64;energysecurity.gov.uk </a>
  </li>
  <li>
    EA (for aviation):
    <a class="govuk-link" href="mailto:etaviationhelp@environment-agency.gov.uk"> etaviationhelp&#64;environment-agency.gov.uk </a>
  </li>
</ul>
